import React,{useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AudioPlayer from 'react-h5-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeDown } from '@fortawesome/free-solid-svg-icons'

// wysiwyg editor libs
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/editor.css';

import { Row, Container, Col, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import {BASE_URL, API_DAYS, API_COMMENT} from '../../constants';
import Loading from '../../components/loading';

import Header from '../../components/header';
import AboutRow from '../../components/about';
import VideoPlayer from '../../components/video';
import Comment from '../../components/comments/comment';
import DayCard from '../home/day';
import AccordionRow from './accordion';
import ContentLink from './contentLink';
import Lock from './lock';
import {Context} from '../../hooks/store';

import DayLink from './link';
import DayDownload from './download';

import './day.css';


// Schema for yup
const validationSchema = Yup.object().shape({
  comment: Yup.string()
  .email("*Must be a valid email address")
  .max(100, "*Email must be less than 100 characters")
});


const Day = (props) => {
  const [state] = useContext(Context);
  const [day, setDay] = useState({});
  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [links, setLinks] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [dayContent, setDayContent] = useState([]);
  const [error, setError] = useState([]);
  //state for editor with initial value
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    ),
  );
  // store converted text extracted from the editor
  const  [convertedContent, setConvertedContent] = useState(null);
  const [comments, setComments] = useState('');

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint){
      const a = await fetch(endpoint);
      const aJson = await a.json();
      return aJson;
    }

    // fetch all data needed
    (async function(){
      const _day = await fetchData(`${API_DAYS}/index/${props.match.params.id}`);
      setDay(_day);
      // use props.locations.state if extists or wait until _day is populated
      const _dc = await fetchData(`${BASE_URL}/day-contents/day/${props.location.state ? props.location.state.id : _day.id}`);
      setDayContent(_dc)

      const _videos = await fetchData(`${BASE_URL}/videos?day=${props.location.state ? props.location.state.id : _day.id}&_sort=created_at:ASC`);
      setVideos(_videos)

      const _comments = await fetchData(`${API_COMMENT}?day=${props.location.state ? props.location.state.id : _day.id}&_sort=created_at:DESC`);
      setComments(_comments)

      const _audios = await fetchData(`${BASE_URL}/audio-files?day=${props.location.state ? props.location.state.id : _day.id}`);
      setAudios(_audios)

      const _links = await fetchData(`${BASE_URL}/links?day=${props.location.state ? props.location.state.id : _day.id}`);
      setLinks(_links)

      const _pdfs = await fetchData(`${BASE_URL}/pdf-files?day=${props.location.state ? props.location.state.id : _day.id}`);
      setPdfs(_pdfs)


    })();

}, [props.location, props.match.params.id])

  const handleEditorChange = (_state) =>{
    setEditorState(_state);
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  async function postData(payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.jwt}`
      }
    }
    const req = await fetch(API_COMMENT, options);
    return req
  }

  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Col md={{span:12}} className="mt-10 mb-5 pl-0">
          <Container className="pr-md-0 pl-md-0 mb-6" fluid>
            <Row>
              <Col md={{span:2}}>
                {Object.entries(day).length
                  ?
                  <DayCard
                    key={day.index}
                    header='Day'
                    headerClass='day-card-header'
                    index={day.index}
                    title={day.title}
                    indexClass='about-card-index'
                    // titleClass='about-card-title'
                    className="day-card mb-3  mr-0 mr-md-1"
                  />
                  :
                  <Loading/>
                }
              </Col>
              <Col md={{span: 4, offset: 1}}>
                {videos.length > 0
                  ?
                  <>
                    {videos.map((video) =>(
                      <>
                      {video.created_at
                        ?
                        <Row key={video.id} className="mb-2">
                          <VideoPlayer
                            src={video}
                            title={video.title}/>
                          <AccordionRow
                            title={video.title}
                            txt={video.credits}
                            id={video.id}
                            className="mb-4"
                          />
                        </Row>
                        :null
                      }
                      </>
                    ))}
                  </>
                  :null
                }
                {Object.entries(day).length
                  ? <AboutRow
                      txt={day.about}
                      className="mb-5"
                    />
                  :null
                }
                {audios.length
                  ?
                  <>
                    {audios.map((file)=>(
                      <>
                      {file.locked && !state.jwt
                        ?
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={`audio-${file.id}`}>
                              Login to access this content
                            </Tooltip>
                          }
                        >
                          <Link to='/login'>
                          <h1>
                            <FontAwesomeIcon icon={faVolumeDown} className="mr-2"/>{file.title} <Lock/>
                          </h1>
                         </Link>
                       </OverlayTrigger>
                        :
                        <Row key={file.id}>
                          <AudioPlayer
                            autoPlay={false}
                            src={`${BASE_URL}${file.file.url}`}
                            className="mb-1"
                          />
                          <AccordionRow
                            key={file.id}
                            title={file.title}
                            txt={file.credits}
                            id={file.id}
                            className="mb-4"
                          />
                        </Row>
                      }
                      </>
                  ))}
                  </>
                  :null
                }
              </Col>
              <Col md={{span:3, offset:1}}>
                {Object.entries(day).length
                  ?
                  <>
                    {dayContent.length
                      ?
                        <>
                          {dayContent.map((content)=>(
                            <ContentLink
                              key={content.id}
                              day={day.index}
                              to={content.id}
                              title={content.title}
                              author={content.author}
                              locked={content.locked}
                              className='mb-4'
                              contentId={content.id}
                            />
                          ))}
                          <Link
                            to={{
                              pathname: '/submit',
                              state: {day: day}
                            }}
                            >
                            <h3 className="mt-3 mb-3">
                              Submit new content
                              <Lock/>
                            </h3>
                          </Link>
                        </>
                      : null
                    }
                    {pdfs
                      ?
                      <div className="mt-5 mb-5">
                        {pdfs.length > 0 ? <h3 className="mb-4">Pdf</h3> : null}

                        {pdfs.map((link) =>(
                          <div className="mb-2">
                            <DayDownload
                              key={link.id}
                              url={`${BASE_URL}${link.file.url}`}
                              title={link.title}
                              filename={link.file.filename}
                              locked={link.locked? link.locked: false}
                              fileId={link.id}
                            />
                          </div>
                        ))}
                      </div>
                    : null
                    }
                    {links
                      ?
                      <div className="mt-5 mb-5">
                        {links.length > 0 ? <h3>Links</h3> : null}

                        {links.map((link) =>(
                          <DayLink
                            key={link.id}
                            url={link.url}
                            title={link.title}
                          />
                        ))}
                      </div>
                    : null
                    }

                    {comments
                      ?
                      <>
                        <h3>Comments</h3>
                        {comments.map((comment)=>(
                          <Comment
                            key={comment.id}
                            content={comment.content}
                            user={comment.user}
                            date={comment.created_at}
                            className="mb-4"
                          />
                        ))}
                      </>
                      : null
                    }
                  </>
                  :
                  <Loading/>
                }
                {state.jwt
                  ?
                  <>
                  <Row>
                    <Col>
                        <Formik
                          initialValues={{comment:""}}
                          validationSchema={validationSchema}
                          onSubmit={(values, {setSubmitting, resetForm}) => {
                              // When button submits form and form is in the process of submitting, submit button is disabled
                              setSubmitting(true);

                              (async function(){
                                const req = await postData({
                                  day: day.id,
                                  user: state.user.id,
                                  content: convertedContent
                                })

                                const response = await req.json();

                                if(req.status === 400){
                                  console.log('error', response);
                                  setError(response.message);
                                  resetForm();
                                  setSubmitting(false);
                                }

                                if(req.status === 200){
                                  setComments(comments => [...comments, response]);
                                  resetForm();
                                  setSubmitting(false);
                                  console.log(comments);
                                }
                              })();
                          }}
                        >
                      {( {values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="comment">
                          <Form.Label>Comment</Form.Label>
                          <Form.Control
                            name="comment"
                            as={Editor}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            toolbar={{
                              options: ['inline']
                            }}
                            placeholder="Your comment"
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            defaultContentState={editorState}
                            // onChange={handleChange}
                            onBlur={handleBlur}
                            value={convertedContent}
                            className={touched.comment && errors.comment ? "error" : null}
                          />
                          <Form.Text className="text-muted">
                          {touched.comment && errors.comment
                            ? <span>{errors.comment}</span>
                            : <span></span>
                          }
                          </Form.Text>
                        </Form.Group>

                        {isSubmitting
                          ? <Loading/>
                          :
                          <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                            Comment
                          </Button>
                        }
                      </Form>
                    )}
                  </Formik>
                    </Col>
                  </Row>
                  <Row>
                    {error.length > 0
                      ? <h4 className='error-message'>
                        {error.map((items) =>(
                            <>
                            {items.messages.map((message) =>(
                              <>
                                <span>{message.message}</span>
                              </>
                            ))}
                            </>
                        ))}
                      </h4>
                      :null
                    }
                  </Row>
                  </>
                  : null
                }
              </Col>
            </Row>
          </Container>
          <Col md={{span:8, offset:3}} className="day mt-5 mb-5">
          <Col md={2}>

          </Col>

          <Col md={{span:6, offset:1}}>

          </Col>
          </Col>
        </Col>
      </Col>
    </>
  )
}

export default Day;
