import React,{useContext} from 'react';
import {Link} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {Context} from '../../hooks/store';

import Lock from './lock';

const styles = createUseStyles({
  link:{
    display: 'block'
  },
  author:{
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: '.8rem !important',
    paddingLeft: '.2rem'
  },
  lock:{
    marginLeft: '.6rem'
  }
})

const ContentLink = (props) => {
  const [state] = useContext(Context);
  const classes = styles();

  return (
    <>
    {props.locked && !state.jwt
      ?
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id={`content-${props.contentId}`}>
            Login to access this content
          </Tooltip>
        }
      >
      <div className={props.className}>
        <Link
          to={`/day/${props.day}/content/${props.to}`}
          className={`${classes.link}`}
        >
          <h3>
            {props.title}
          </h3>
        </Link>
        <Link
          to={`/person/${props.author.slug}`}
        >
        <h5 className={classes.author}>
          {props.author.name}
          {props.locked
            ?
            <Lock/>
            : null
          }
        </h5>
      </Link>
    </div>
    </OverlayTrigger>
      :
      <div className={props.className}>
        <Link
          to={`/day/${props.day}/content/${props.to}`}
          className={`${classes.link}`}
        >
          <h3>
            {props.title}
          </h3>
        </Link>
        <Link
          to={`/person/${props.author.slug}`}
        >
        <h5 className={classes.author}>
          {props.author.name}
          {props.locked
            ?
            <Lock/>
            : null
          }
        </h5>
      </Link>
    </div>
    }
    </>
  )
}

export default ContentLink
