import React,{useState,useContext,useEffect} from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import DOMPurify from 'dompurify';

// wysiwyg editor libs
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/editor.css';

import {Form, Button} from 'react-bootstrap';

import {BASE_URL} from '../../constants';
import {Context} from '../../hooks/store';
import Loading from '../../components/loading';
import ApiError from '../../components/apiError';

// Schema for yup
const validationSchema = Yup.object().shape({
  title: Yup.string()
  .min(5, "* min 5 characters *")
  .max(100, "* less than 100 characters *")
  .required("* Title is required *"),
  file: Yup.mixed()
  .required('PDF file is required')
  .test(
    'FILE_SIZE',
    '* file is too big *',
    value => value && value.size <= (20 * 1024 * 1024) //20mb
  )
  .test(
    'FILE_FORMAT',
    '* Selected fie has an unsupported format *',
    value => value && value.type === 'application/pdf'
  )
});


const Pdf = (props) => {
  const [state] = useContext(Context);
  const [error, setError] = useState([]);
  //state for editor with initial value
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    ),
  );
  // store converted text extracted from the editor
  const  [convertedContent, setConvertedContent] = useState(null);

  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.jwt}`
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  //Post file to api
  // include image, auth token and ref fields
  async function postFile(url, payload){
    const data = new FormData();
    //file
    data.append('files', payload.file);
    //name of the model linked to
    data.append('ref', payload.ref);
    //id of the entrie linked to
    data.append('refId', payload.refId);
    //optional: name of the plugin the model is located
    // data.append('source', payload.source);
    //field of the entry the file will be linked to
    data.append('field', payload.field);

    const options = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': `Bearer ${state.jwt}`
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    // no jwt key > redirect
    if (!state.jwt) {
      props.history.push('/login');
    }
  },[state, props])

  const handleEditorChange = (_state) =>{
    setEditorState(_state);
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(DOMPurify.sanitize(currentContentAsHTML));
  }

  return (
    <>
    <Formik
      initialValues={{title:"", information:"", file:""}}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting, resetForm}) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          console.log(values);
          (async function(){
            const req = await postData(`${BASE_URL}/pdf-files`, {
              title: values.title,
              credits: values.information,
              day: props.toId,
              contributor: state.user.id,
              published_at: null
            })

            const response = await req.json();

            if(req.status === 400){
              setError(response.message);
              resetForm();
              setSubmitting(false);
            }

            if(req.status === 200){
              console.log('info uploaded');
              console.log(response);

              if (values.file) {
                const reqFile = await postFile(`${BASE_URL}/upload`, {
                  file: values.file,
                  ref: 'pdf-file',
                  refId: response.id,
                  field: 'file'
                })

                if(reqFile.status === 200){
                  // const response = await reqFile.json();
                  // console.log(response);
                  props.history.push('/submit/response');
                }

                // error handler
                if(reqFile.status === 400){
                  const response = await reqFile.json();
                  setError(response.message);
                  resetForm();
                  setSubmitting(false);
                }

              }
            }
          })();
      }
    }
      >
      {( {values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue, props }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            type="title"
            placeholder="My contribution"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            className={touched.title && errors.title ? "error" : null}
          />
          <Form.Text className="text-muted">
          {touched.title && errors.title
            ? <span>{errors.title}</span>
            : <span>The name of the resource</span>
          }
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="file">
          <Form.Label>PDF file</Form.Label>
          <Form.Control
            name="file"
            type="file"
            onChange={(e)=>{setFieldValue('file', e.currentTarget.files[0])}}
            onBlur={handleBlur}
            className={touched.file && errors.file ? "error" : null}
          />
          <Form.Text className="text-muted">
          {touched.file && errors.file
            ? <span>{errors.file}</span>
            : <span>PDF file</span>
          }
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="information">
          <Form.Label>Information</Form.Label>
          <Form.Control
            name="information"
            as={Editor}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              options: ['inline']
            }}
            placeholder="Information"
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            defaultContentState={editorState}
            onBlur={handleBlur}
            onChange={(e)=>{
              convertContentToHTML(); //convert and sanitize content
              setFieldValue('information', convertedContent) //set editor content to values
            }}
            className={touched.information && errors.information ? "error" : null}
          />
          <Form.Text className="text-muted">
          {touched.information && errors.information
            ? <span>{errors.information}</span>
            : <span>Information about your contribution</span>
          }
          </Form.Text>
        </Form.Group>

        {isSubmitting
          ? <Loading/>
          :
          <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
            Submit
          </Button>
        }
      </Form>
    )}
  </Formik>
  {/* Show errors from API */}
  {error.length > 0
    ?
    <ApiError error={error} className="mt-3"/>
    : null
  }
    </>
  )
}

export default Pdf
