import React,{useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Row, Container, Col} from 'react-bootstrap';

import {BASE_URL} from '../../constants';
import Header from '../../components/header';
import AboutRow from '../../components/about';
import DayCard from '../home/day';
import Loading from '../../components/loading';
import VideoPlayer from '../../components/video';
import AccordionRow from './accordion';
import {Context} from '../../hooks/store';
import DayLink from './link';
import DayDownload from './download'



const DayContent = (props) => {
  const [state] = useContext(Context);
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint){
      const a = await fetch(endpoint);
      const aJson = await a.json();
      return aJson;
    }

    async function fetchAuth(method, payload, endpoint){
      let options;

      if (method === 'GET') {
          options = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${state.jwt}`
            }
          }
      } else {
        options = {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.jwt}`
          }
        }
      }

      const req = await fetch(endpoint, options);
      const reqJson = await req.json()
      return reqJson
    }

    // authenticated request
    if(state.jwt && Object.entries(content).length === 0){
        (async function(){
          const _dc = await fetchAuth('GET', '', `${BASE_URL}/day-contents/${props.match.params.contentId}`);
          setLoading(false);
          setContent(_dc)
        })();

    }

    // public request
    if(!state.jwt && Object.entries(content).length === 0){
        (async function(){
          const _dc = await fetchData(`${BASE_URL}/day-contents/${props.match.params.contentId}`);
          setLoading(false);
          setContent(_dc)
        })();

    }

  },[content, props.match.params, state.jwt])

  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Col md={{span:12}} className="mt-10 mb-5 pl-0 ml-md-0">
          <Container className="pr-md-0 pl-md-0 mb-6" fluid>
            <Row>
              <Col md={{span:2}}>
                {!content.statusCode && content.day
                  ?
                  <DayCard
                    header='Day'
                    headerClass='about-card-header'
                    index={content.day.index}
                    title={content.day.title}
                    indexClass='about-card-index'
                    // titleClass='about-card-title'
                    className="day-card mb-3  mr-0 mr-md-1"
                  />
                  :null
                }
              </Col>
              <Col md={{span: 6}}>
                {loading
                  ? <Loading/>
                  :null
                }
                {content.statusCode === 403
                  ? <h1>To access this content you need to login / register</h1>
                  : null
                }
                {!content.statusCode
                  ? <AboutRow
                      txt={content.about}
                      className="mb-5"
                    />
                  : null
                }
                {!content.statusCode && content.videos
                  ?
                  <>
                    {content.videos.map((video) =>(
                      <Row key={video.id} className="mb-5">
                        <VideoPlayer src={video} title={video.title}/>
                        <AccordionRow
                          title={video.title}
                          txt={video.credits}
                          id={video.id}
                          className="mb-4"
                        />
                      </Row>

                    ))}
                  </>
                  :null
                }
                {!content.statusCode && content.audio_files
                  ?
                  <>
                    {content.audio_files.map((file)=>(
                      <Row key={file.id}>
                        <AudioPlayer
                          autoPlay={false}
                          src={`${BASE_URL}${file.file.url}`}
                          className="mb-1"
                        />
                        <AccordionRow
                          key={file.id}
                          title={file.title}
                          txt={file.credits}
                          id={file.id}
                          className="mb-4"
                        />
                      </Row>
                  ))}
                  </>
                  :null
                }
              </Col>
              <Col md={{span:2, offset:1}}>
                {!content.statusCode && content.author
                  ?
                  <div className="mb-5">
                    <h2>Author</h2>
                    <Link
                      to={`/person/${content.author.slug}`}
                      >{content.author.name}</Link>
                  </div>
                  : null
                }
                {!content.statusCode && content.pdf_files
                  ?
                  <div className="mb-5">
                    {content.pdf_files.length > 0 ? <h2>PDF</h2> :null}

                    {content.pdf_files.map((file) =>(
                      <DayDownload
                        key={file.id}
                        url={`${BASE_URL}${file.file.url}`}
                        title={file.title}
                        filename={file.file.filename}
                      />
                    ))}
                  </div>
                  : null
                }
                {!content.statusCode && content.links
                  ?
                  <>
                    {content.links.length > 0 ? <h2>Links</h2> : null}

                    {content.links.map((link) =>(
                      <DayLink
                        key={link.id}
                        url={link.url}
                        title={link.title}
                      />
                    ))}
                  </>
                : null
                }

              </Col>
            </Row>
          </Container>
        </Col>
      </Col>
    </>
  )
}

export default DayContent
