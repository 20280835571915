import React,{useEffect, useContext}from 'react'
import cookie from 'react-cookies';

import {Context} from '../../hooks/store';

const LogOut = (props) => {
  const [state, dispatch] = useContext(Context);

  useEffect(()=>{


    if(state.jwt){
      cookie.remove('decuser')
      cookie.remove('decuserid')
      cookie.remove('decusername')

      dispatch({type: 'SET_USER', payload: null});
      dispatch({type: 'SET_JWT', payload: null});

      props.history.push('/')
    }

  },[state.jwt, props, dispatch])

  return (
    <div />
  )
}

export default LogOut
