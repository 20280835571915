import React from 'react';
import {Link} from 'react-router-dom';

const Number = (props) => {
  return (
    <Link to={{pathname: props.to, state: props.state}} >
      <span
        className={`${props.className} text-center`}
        style={{
          color: props.mainColor,
          fontSize: props.size.width/1.9
        }}
       >
        {props.txt}
      </span>
    </Link>
  );
}

export default Number;
