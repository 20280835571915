import React,{useEffect, useContext} from 'react';
import {createUseStyles} from 'react-jss';

import {Col, Container, Row} from 'react-bootstrap';

import Header from '../../components/header';
import DayCard from '../home/day';
import {Context} from '../../hooks/store';

import Link from './link';
import Audio from './audio';
import Video from './video';
import Pdf from './pdf';

const styles = createUseStyles({
  capitalize: {
    textTransform: 'capitalize'
  }
})

const SubmitByType = (props) => {
  const [state] = useContext(Context);
  const classes = styles();

  useEffect(()=>{
    if(!state.jwt){
      console.log('nop');
      // props.history.push('/login');
    }
  },[props, state])


  return (
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Col md={{span:11, offset:1}} className="mt-10 mb-5 pl-0">
        <Container className="pr-md-0 pl-md-0 mb-6">
          <Row>
            <Col md={{span:2}}>
              {props.location.state
                ?
                <DayCard
                  key={props.location.state.day.index}
                  header='Day'
                  headerClass='about-card-header'
                  index={props.location.state.day.index}
                  title={props.location.state.day.title}
                  indexClass='about-card-index'
                  // titleClass='about-card-title'
                  className="day-card mb-3  mr-0 mr-md-1"
                />
              :null
              }
            </Col>
            <Col md={{span:6, offset:1}}>
              <h1 className={`${classes.capitalize} mb-5`}>{props.match.params.type} submision</h1>

              {props.match.params.type === 'video'
                ? <Video toId={props.location.state.day.id} history={props.history}/>
                : null
              }

              {props.match.params.type === 'audio'
                ? <Audio toId={props.location.state.day.id} history={props.history}/>
                : null
              }

              {props.match.params.type === 'pdf'
                ? <Pdf toId={props.location.state.day.id} history={props.history}/>
                : null
              }

              {props.match.params.type === 'link'
                ? <Link toId={props.location.state.day.id} history={props.history}/>
                : null
              }

            </Col>
          </Row>
        </Container>
      </Col>
    </Col>
    </>
  )
}

export default SubmitByType
