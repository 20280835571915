import React,{useState, useEffect, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

import {Col, Row, Form, Button} from 'react-bootstrap/';

import Loading from '../../components/loading';
import Header from '../../components/header';
import ApiError from '../../components/apiError';
import HtmlText from '../../components/htmlText';

import {BASE_URL, API_REGISTER} from '../../constants';
import {Context} from '../../hooks/store';

import '../../css/register.css';

// Schema for yup
const validationSchema = Yup.object().shape({
  username: Yup.string()
  .min(3, "* Username must have at least 3 characters *")
  .max(100, "* Username can't be longer than 100 characters *")
  .required("* Username is required *"),
  fullName: Yup.string()
  .min(6, "* Full name must have at least 3 characters *")
  .max(100, "* Full name can't be longer than 100 characters *")
  .required("* Full name is required *"),
  email: Yup.string()
  .email("* Must be a valid email address *")
  .max(100, "* Email must be less than 100 characters *")
  .required("* Email is required *"),
  password: Yup.string()
  .min(8, "* Password must have at least 8 characters *")
  .max(20, "* Password can't be longer than 20 characters *")
  .required("* Password is required *"),
  captcha: Yup.string()
  .length(6, "* Please insert the 6 characters in the image *")
  .test("captcha-test", "Text doesn't match", value => {
    if(value && value.length === 6){
      return validateCaptcha(value, false);
    }
  })
  .required("* Captcha is required *")
});

const Register = (props) =>{
  const [state, dispatch] = useContext(Context);
  const [error, setError] = useState([]);
  const [info, setInfo] = useState([]);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      setInfo(aJson)
    }

    if (!info.length) {
      fetchData(`${BASE_URL}/register`);
    }
    //setup captcha
    loadCaptchaEnginge(6);

  },[info.length])

  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  return(
    <>
      <Header/>
        <Col className="flex-grow-1">
          <Col id="form-container" md={{span:11, offset:1}} className="mt-md-10 mt-3  mb-5">
            <Row>
              <Col md={{span:5, offset:1}}>
                <h1 className="mb-4 mt-md-5">Register</h1>
                  <Formik
                    initialValues={{username:"", fullName:"", email:"", password:"", captcha:""}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true);

                        (async function(){
                          const req = await postData(API_REGISTER, {
                            username: values.username,
                            name: values.fullName,
                            email: values.email,
                            password: values.password
                          })

                          if(req.status === 400){
                            const response = await req.json();
                            setError(response.message);
                            resetForm();
                            setSubmitting(false);
                          }

                          if(req.status === 200){
                            const response = await req.json();
                            // console.log(response);
                            dispatch({type: 'SET_USER', payload: response.user});
                            props.history.push('/register/success', state);
                          }
                        })();
                    }}
                  >
                  {( {values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="username">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        name="username"
                        required type="text"
                        placeholder="Username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        className={touched.username && errors.username ? "error" : null}
                      />
                      <Form.Text className="text-muted">
                      {touched.username && errors.username
                        ? <span>{errors.username}</span>
                        : <span>Your nickname</span>
                      }
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="fullName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        name="fullName"
                        type="text"
                        placeholder="Name Lastname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                        className={touched.fullName && errors.fullName ? "error" : null}
                      />
                      <Form.Text className="text-muted">
                      {touched.fullName && errors.fullName
                        ? <span>{errors.fullName}</span>
                        : <span>Your name</span>
                      }
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        placeholder="user@host.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={touched.email && errors.email ? "error" : null}
                      />
                      <Form.Text className="text-muted">
                      {touched.email && errors.email
                        ? <span>{errors.email}</span>
                        : <span>We'll never share your email with anyone else.</span>
                      }
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={touched.password && errors.password ? "error" : null}
                      />
                      <Form.Text className="text-muted">
                      {touched.password && errors.password
                        ? <span>{errors.password}</span>
                        : <span>Set your account password.</span>
                      }
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="captcha">
                      <Form.Label>Captcha</Form.Label>
                      <LoadCanvasTemplate
                        reloadText="Reload Captcha"
                      />
                      <Form.Control
                        name="captcha"
                        type="text"
                        placeholder="Enter the text displayed above"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.captcha}
                        autoComplete="off"
                        className={touched.captcha && errors.captcha ? "error mt-3" : 'mt-3'}
                      />
                    <Form.Text className="text-muted">
                      {touched.captcha && errors.captcha
                        ? <span>{errors.captcha}</span>
                        : <span></span>
                      }
                      </Form.Text>
                    </Form.Group>

                    {isSubmitting
                      ? <Loading/>
                      :
                      <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                        Register
                      </Button>
                    }
                  </Form>
                )}
                </Formik>
                {/* Show errors from API */}
                {error.length > 0
                ?
                <ApiError error={error} className="mt-3"/>
                : null
                }
              </Col>
              <Col md={{span:4, offset:1}}>
                {info
                  ? <HtmlText content={info.information}/>
                  : <Loading/>
                }
              </Col>
            </Row>
          </Col>
        </Col>
    </>
  )
}

export default Register;
