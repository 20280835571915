import React,{ useEffect, useState} from 'react';

import Row from 'react-bootstrap/row';
import Col from 'react-bootstrap/col';

import Header from '../../components/header';
import Loading from '../../components/loading';
import {BASE_URL} from '../../constants';

import Profile from './profile';

const PublicProfile = (props) =>{
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')
    
    async function fetchData(endpoint){
      const options ={
        method: 'GET',
      }
      const a = await fetch(endpoint, options);
      const aJson = await a.json();
      setUser(aJson);
      setLoaded(true);
    }

    if(Object.keys(user).length < 1){
      fetchData(`${BASE_URL}/users?slug=${props.match.params.slug}`);
    }
  },[props, user])

  return (
    <>
      <Header/>
        <Col className="flex-grow-1">
          <Col md={{span:11, offset:1}} className="mt-10 mb-5">
            <Row>
              {loaded
                ?
                <>
                  {Object.keys(user).length > 0
                    ?
                    <Profile user={user[0]}/>
                    :
                    <>
                      {props.history.push('/404')}
                    </>
                  }
                </>
                : <Loading/>
              }
            </Row>

          </Col>
        </Col>
    </>
  )
}

export default PublicProfile;
