import React from 'react';

import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed'

const VideoPlayer = (props) => {
  return (
      <>
      {props.src.youtube_id
        ?
        <ResponsiveEmbed aspectRatio="16by9">
          <iframe src={`https://www.youtube.com/embed/${props.src.youtube_id}`}
            title={props.title ?props.title :'Decameron video'}
            frameBorder={props.border ?props.border :0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </ResponsiveEmbed>
        : null
      }
      {props.src.vimeo_id
        ?
        <ResponsiveEmbed aspectRatio="16by9">
          <iframe
            src={`https://player.vimeo.com/video/${props.src.vimeo_id}`}
            title={props.title}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
          </iframe>
        </ResponsiveEmbed>
        :null
      }
      </>
  )
};

export default VideoPlayer;
