import React from 'react';

import Moment from 'react-moment';

const Date = (props) => {
  return (
    <>
      <Moment
        date={props.date}
        format="DD/MM/YYYY"
        className = {props.className}
        style = {props.style}
      />
    </>
  );
}


export default Date;
