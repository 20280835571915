// export const BASE_URL = "http://localhost:62160"
export const BASE_URL = "https://api.decameron.ch"

export const API_SOCIAL = `${BASE_URL}/social-networks`;
export const API_ABOUT = `${BASE_URL}/about`;
export const API_AUDIO = `${BASE_URL}/audio-file`;
export const API_COMMENT = `${BASE_URL}/comments`;
export const API_CONTRIBUTOR  = `${BASE_URL}/contributors`;
export const API_CREW = `${BASE_URL}/crews`;
export const API_DAYS = `${BASE_URL}/days`;
export const API_DAY_CONTENT = `${BASE_URL}/day-content`;
export const API_PARTNERS = `${BASE_URL}/partners`;
export const API_PDF = `${BASE_URL}/pdf-file`;
export const API_VIDEO = `${BASE_URL}/video`;
export const API_REGISTER = `${BASE_URL}/auth/local/register`;
export const API_LOGIN = `${BASE_URL}/auth/local`;
export const API_FORGOT = `${BASE_URL}/auth/forgot-password`;
export const API_RESET = `${BASE_URL}/auth/reset-password`;
