import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import Header from '../../components/header';



const ResetSuccess = (props) => {
  return (
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Container className="h-100">
        <Row className="h-100">
          <Col md={{span:5, offset: 3}}className="h-100 d-table">
            <div className="d-table-cell align-middle">
              <h1 className="mb-3">Password Successfully changed!</h1>
              <Link to="/login">Login</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
    </>
  )
}

export default ResetSuccess
