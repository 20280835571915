import React,{useEffect, useState, useContext} from 'react';

import Row from 'react-bootstrap/row';
import Col from 'react-bootstrap/col';

import Header from '../../components/header';
import Loading from '../../components/loading';
import {BASE_URL} from '../../constants';
import {Context} from '../../hooks/store';

import Profile from './profile';
import './user.css'

const PrivateProfile = (props) =>{
  const [state, dispatch] = useContext(Context);
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    if(!state.jwt){
      props.history.push('/login');
    }

    async function fetchData(endpoint, eventType){
      const options ={
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${state.jwt}`
        }
      }
      const a = await fetch(endpoint, options);
      const aJson = await a.json();

      dispatch({type: 'SET_USER', payload: aJson});
      setLoaded(true);
    }

    if(state.jwt){
      fetchData(`${BASE_URL}/users/me`, 'SET_USER');
    }
  },[dispatch, props.history, state.jwt])


  return (
    <>
      <Header/>
        <Col className="flex-grow-1">
          <Col md={{span:11, offset:1}} className="mt-10 mb-5">
            <Row>
              {loaded
                ?
                <>
                  {Object.keys(state.user).length > 0
                    ?
                    <Profile user={state.user} edit={true}/>
                    :
                    <>
                      {props.history.push('/404')}
                    </>
                  }
                </>
                : <Loading/>
              }
            </Row>

          </Col>
        </Col>
    </>
  )
}

export default PrivateProfile;
