import React from 'react';
import {Link} from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import { Row, Card } from 'react-bootstrap';

import Date from '../../components/date';
import createMarkup from '../../utils/createMarkup';


const styles = createUseStyles({
  card: {
    border: 'none',
    width: '100%'
  },
  header: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'transparent'
  },
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '.8rem !important'
  },
  footer: {
    paddingTop: 0,
    textAlign: 'right',
    border: 'none',
    backgroundColor: 'transparent'
  }
})

const Comment = (props) => {
  const classes = styles();

  return (
    <Row className={props.className}>
      <Card className={classes.card}>
        <Card.Header className={classes.header}>
          <Link to={`/person/${props.user.slug}`}>
            <h4 className="mb-0">{props.user.username}</h4>
          </Link>
        </Card.Header>
        <Card.Body
          className = {classes.body}
          dangerouslySetInnerHTML={createMarkup(props.content)}/>
        <Card.Footer className={classes.footer}>
          <Date date={props.date}/>
        </Card.Footer>
      </Card>
    </Row>
  );
}


export default Comment;
