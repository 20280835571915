import React,{useState, useEffect} from 'react';

import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';

import Header from '../../components/header';
import Loading from '../../components/loading';
import {BASE_URL} from '../../constants';

const Contact = (props) => {
  const [item, setItem] = useState({});

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      setItem(aJson);
    }

    if (!Object.entries(item).length) {
      fetchData(`${BASE_URL}/contact`)
    }

  },[item])

  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Container className="h-100">
          <Row className="h-100">
            <Col md={{offset: 2}}className="h-100 d-table">
              <div className="d-table-cell align-middle">
                {Object.entries(item).length
                  ?
                  <>
                    <h1>{item.message}</h1>
                    <h1>{item.email}</h1>
                  </>
                  :
                  <Loading/>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </>
  )
}

export default Contact
