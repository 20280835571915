import React,{useEffect} from 'react';

import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';
import Col from 'react-bootstrap/col';

import Header from '../components/header';

const NotFound = (props) => {
  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')
  },[])

  return(
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Container className="h-100">
        <Row className="h-100">
          <Col md={{offset: 2}}className="h-100 d-table">
            <div className="d-table-cell align-middle">
              <h1>404</h1>
              <h1>Page not found</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
    </>
  )
}

export default NotFound;
