import React,{useState, useContext, useEffect} from 'react';

import Col from 'react-bootstrap/col';


import {BASE_URL, API_DAYS} from '../../constants';
import {Context} from '../../hooks/store';
import Loading from '../../components/loading';
import Header from '../../components/header';

import DayCard from './day';
import './home.css';

const Home = (props) => {
  const [state, dispatch] = useContext(Context);
  const [daysLoaded, setDaysLoaded] = useState(false);
  const [homeSettings, setHomeSettings] = useState({});

  useEffect(() =>{
    async function fetchData(endpoint, eventType){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      if(eventType === 'homeSettings'){
        setHomeSettings(aJson);
      } else {
        dispatch({type: eventType, payload: aJson});
      }
    }

    if(!state.days.length && !daysLoaded){
      setDaysLoaded(true);
      fetchData(`${API_DAYS}`, 'SET_DAYS')
    }

    if(Object.entries(homeSettings).length === 0){
      fetchData(`${BASE_URL}/home-page`, 'homeSettings')
    } else {
      // inject background image if is stored in db
      if (homeSettings.background_image){
        document.body.style.backgroundImage = (`url(${BASE_URL}${homeSettings.background_image.url})`)
        document.body.style.height = 'auto';
        document.body.style.maxWidth = '100%';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.minWidth = '100vw';
      }
    }

  },[dispatch, state.days, daysLoaded, homeSettings])

  return(
    <>
      <Header
        mainColor={homeSettings.main_color ? homeSettings.main_color : null}
        secondaryColor={homeSettings.secondary_color ? homeSettings.secondary_color : null}
      />
        <Col className="flex-grow-1">
          <Col  md={{span:12}} className="home-container mt-5 ml-md-5">
          <div className="row row-cols-1 row-cols-md-4">
            {state.days.length > 0
              ?
              <>
                {state.days.map((day) => (
                  <DayCard
                    key={day.index}
                    state={day}
                    mainColor={homeSettings.main_color ? homeSettings.main_color : null}
                    header='Day'
                    headerClass='home-card-header'
                    headerStyle={homeSettings.secondary_color ? {color:homeSettings.secondary_color} : null}
                    index={day.index}
                    indexClass='home-card-index'
                    title={day.title}
                    titleClass='home-card-title'
                    titleStyle={homeSettings.secondary_color ? {color:homeSettings.secondary_color} : null}
                    className="home-card mb-5 mr-0 mr-md-5"
                  />
                ))}
              </>
              :
              <Loading/>
            }
          </div>

          </Col>
        </Col>
    </>
  )
}

export default Home;
