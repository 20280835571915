import React,{useState,useContext,useEffect} from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import DOMPurify from 'dompurify';
import youtubeRegrex from 'youtube-regex';
import vimeoRegex from 'vimeo-regex';

// wysiwyg editor libs
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/editor.css';

import {Form, Button} from 'react-bootstrap';

import {BASE_URL} from '../../constants';
import {Context} from '../../hooks/store';
import Loading from '../../components/loading';
import ApiError from '../../components/apiError';

// Schema for yup
const validationSchema = Yup.object().shape({
  title: Yup.string()
  .min(5, "* min 5 characters *")
  .max(100, "* less than 100 characters *")
  .required("* Title is required *"),
  youtube: Yup.string()
  .test(
    'notBothAtTheSameTime', // test name
    '* Only one URL is allowed *', // validation message to the user
    // it has to be function definition to use `this`
    function(youtube) {
      const { vimeo } = this.parent;
      if (youtube && vimeo) {
        return false; // when user enters both youtube & vimeo do not validate form
      }
      return true;
    }
  )
  .when(['vimeo'], {
    is: vimeo => !vimeo,
    then: Yup.string().required('* A YouTube or Vimeo URL is required *'),
  })
  .test(
    'Youtube_URL',
    '* Wrong URL *',
    function(value){
      // if empty string return true
      if(value){
          return youtubeRegrex().test(value); // test url
      } else {
        return true
      }
    }
  ),
  vimeo: Yup.string()
  .test(
    'notBothAtTheSameTime', // test name
    '* Only one URL is allowed *', // validation message to the user
    // it has to be function definition to use `this`
    function(vimeo) {
      const { youtube } = this.parent;
      if (youtube && vimeo) {
        return false; // when user enters both youtube & vimeo do not validate form
      }
      return true;
    }
  )
  .when(['youtube'], {
    is: youtube => !youtube,
    then: Yup.string().required('* A YouTube or Vimeo URL is required *'),
  })
  .test(
    'Vimeo_URL',
    '* Wrong URL *',
    function(value){
      // if empty string return true
      if(value){
          return vimeoRegex().test(value); // test url
      } else {
        return true
      }
    }
  )
}, ['youtube', 'vimeo']);

const Video = (props) => {
  const [state] = useContext(Context);
  const [error, setError] = useState([]);
  //state for editor with initial value
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    ),
  );
  // store converted text extracted from the editor
  const  [convertedContent, setConvertedContent] = useState(null);

  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.jwt}`
      }
    }

    const req = await fetch(url, options);
    return req;
  }


  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    // no jwt key > redirect
    if (!state.jwt) {
      props.history.push('/login');
    }
  },[state, props])

  const handleEditorChange = (_state) =>{
    setEditorState(_state);
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(DOMPurify.sanitize(currentContentAsHTML));
  }

  return (
    <>
    <Formik
      initialValues={{title:"", informatio:"", youtube:"", vimeo:""}}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting, resetForm}) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          // console.log(values);

          // extract ids for videos
          let yt, vm = ''

          // run exec over regex if id is found save it
          if (values.youtube) {
            const _yt = youtubeRegrex().exec(values.youtube)
            if (_yt.length>0) {
              yt = _yt[1]
            }
          }

          if (values.vimeo) {
            const _vm = vimeoRegex().exec(values.vimeo)
            if (_vm.length>0) {
              vm = _vm[4]
            }
          }

          // console.log('yt: ', yt);
          // console.log('vm: ', vm);

          (async function(){
            const req = await postData(`${BASE_URL}/videos`, {
              title: values.title,
              credits: values.information,
              day: props.toId,
              youtube_id: yt,
              vimeo_id: vm,
              contributor: state.user.id,
              published_at: null
            })

            const response = await req.json();

            if(req.status === 400){
              setError(response.message);
              resetForm();
              setSubmitting(false);
            }

            if(req.status === 200){
              // console.log('info uploaded');
              // console.log(response);
              props.history.push('/submit/response');
            }
          })();
      }
    }
      >
      {( {values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue, props }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            type="title"
            placeholder="My contribution"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            className={touched.title && errors.title ? "error" : null}
          />
          <Form.Text className="text-muted">
          {touched.title && errors.title
            ? <span>{errors.title}</span>
            : <span>The name of the resource</span>
          }
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="youtube">
          <Form.Label>YouTube</Form.Label>
          <Form.Control
            name="youtube"
            type="youtube"
            placeholder="https://youtube.com/"
            // onChange={handleChange}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.youtube}
            className={touched.youtube && errors.youtube ? "error" : null}
          />
          <Form.Text className="text-muted">
          {touched.youtube && errors.youtube
            ? <span>{errors.youtube}</span>
            : <span>YouTube video url</span>
          }
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="vimeo">
          <Form.Label>Vimeo</Form.Label>
          <Form.Control
            name="vimeo"
            type="vimeo"
            placeholder="https://vimeo.com/"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.vimeo}
            className={touched.vimeo && errors.vimeo ? "error" : null}
          />
          <Form.Text className="text-muted">
          {touched.vimeo && errors.vimeo
            ? <span>{errors.vimeo}</span>
            : <span>Vimeo video url</span>
          }
          </Form.Text>
        </Form.Group>



        <Form.Group className="mb-3" controlId="information">
          <Form.Label>Information</Form.Label>
          <Form.Control
            name="information"
            as={Editor}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              options: ['inline']
            }}
            placeholder="Information"
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            defaultContentState={editorState}
            onBlur={handleBlur}
            onChange={(e)=>{
              convertContentToHTML(); //convert and sanitize content
              setFieldValue('information', convertedContent) //set editor content to values
            }}
            className={`btn ${touched.information && errors.information ? "error" : null}`}
          />
          <Form.Text className="text-muted">
          {touched.information && errors.information
            ? <span>{errors.information}</span>
            : <span>Information about your contribution</span>
          }
          </Form.Text>
        </Form.Group>

        {isSubmitting
          ? <Loading/>
          :
          <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
            Submit
          </Button>
        }
      </Form>
    )}
  </Formik>
  {/* Show errors from API */}
  {error.length > 0
    ?
    <ApiError error={error} className="mt-3"/>
    : null
  }
    </>
  )
}

export default Video
