import React,{useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';
import Form from 'react-bootstrap/form';
import Button from 'react-bootstrap/button';

import Header from '../../components/header';
import {API_RESET} from '../../constants';
import ApiError from '../../components/apiError';
import Loading from '../../components/loading';

import '../../css/register.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Schema for yup
const validationSchema = Yup.object().shape({
  password: Yup.string()
  .min(8, "* Password must have at least 8 characters *")
  .max(20, "* Password can't be longer than 20 characters *")
  .required("* Password is required *"),
  passwordConfirmation: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required("* Password is required *"),
});

const ForgotPassword = (props) =>{
  const [error, setError] = useState([]);
  const query = useQuery();

  useEffect(()=>{
    if(!query.get('code')){
      props.history.push('/forgot_password');
    }
  },[query, props])

  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  return(
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Container className="h-100">
        <Row className="h-100">
          <Col md={{span:5, offset: 3}}className="h-100 d-table">
            <div className="d-table-cell align-middle">
              <h1 className="mb-3">Reset password</h1>

                <Formik
                  initialValues={{password:"", passwordConfirmation:""}}
                  validationSchema={validationSchema}
                  onSubmit={(values, {setSubmitting, resetForm}) => {
                      // When button submits form and form is in the process of submitting, submit button is disabled
                      setSubmitting(true);

                      (async function(){
                        const req = await postData(API_RESET, {
                          code: query.get('code'),
                          password: values.password,
                          passwordConfirmation: values.passwordConfirmation
                        })

                        const response = await req.json();

                        if(req.status === 400){
                          setError(response.message);
                          resetForm();
                          setSubmitting(false);
                        }

                        if(req.status === 200){
                          props.history.push('/reset_success')
                        }
                      })();
                  }}
                >
                {( {values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className={touched.password && errors.password ? "error" : null}
                    />
                    <Form.Text className="text-muted">
                    {touched.password && errors.password
                      ? <span>{errors.password}</span>
                      : <span>Set your new password account.</span>
                    }
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="passwordConfirmation">
                    <Form.Label>Password confirmation</Form.Label>
                    <Form.Control
                      name="passwordConfirmation"
                      type="password"
                      placeholder="Repeat password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordConfirmation}
                      className={touched.passwordConfirmation && errors.passwordConfirmation ? "error" : null}
                    />
                    <Form.Text className="text-muted">
                    {touched.passwordConfirmation && errors.passwordConfirmation
                      ? <span>{errors.passwordConfirmation}</span>
                      : <span>Confirm your new password.</span>
                    }
                    </Form.Text>
                  </Form.Group>
                  {isSubmitting
                    ? <Loading/>
                    :
                    <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                      Reset password
                    </Button>
                  }
                </Form>
              )}
            </Formik>
            {/* Show errors from API */}
            {error.length > 0
              ?
              <ApiError error={error} className="mt-3"/>
              : null
            }
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
    </>
  )
}

export default ForgotPassword;
