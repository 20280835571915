import React,{useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cookie from 'react-cookies';

import Container from 'react-bootstrap/container';
import Col from 'react-bootstrap/col';
import Row from 'react-bootstrap/row';
import Form from 'react-bootstrap/form';
import Button from 'react-bootstrap/button';

import Header from '../../components/header';
import Loading from '../../components/loading';
import ApiError from '../../components/apiError';
import {API_LOGIN} from '../../constants';
import {Context} from '../../hooks/store';

import '../../css/register.css';

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
  .email("*Must be a valid email address")
  .max(100, "*Email must be less than 100 characters")
  .required("*Email is required"),
  password: Yup.string()
  .min(8, "*Password must have at least 8 characters")
  .max(20, "*Password can't be longer than 20 characters")
  .required("*Password is required")
});

const Login = (props) =>{
  const [state, dispatch] = useContext(Context);
  const [error, setError] = useState([]);

  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  // if user is logged in redirect to profile
  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    // setJwt(cookie.load('decuser'));
    // redirect if jwt is stored in global state
    if(state.jwt){
      props.history.push('/me');
    }

  },[state, props])

  return(
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Container className="h-100">
        <Row className="h-100">
          <Col md={{span:5, offset: 3}}className="h-100 d-table">
            <div className="d-table-cell align-middle">
              <h1 className="mb-3 mt-5 mt-md-0">Login</h1>
                <Formik
                  initialValues={{email:"", password:""}}
                  validationSchema={validationSchema}
                  onSubmit={(values, {setSubmitting, resetForm}) => {
                      // When button submits form and form is in the process of submitting, submit button is disabled
                      setSubmitting(true);

                      (async function(){
                        const req = await postData(API_LOGIN, {
                          identifier: values.email,
                          password: values.password
                        })

                        const response = await req.json();

                        if(req.status === 400){
                          setError(response.message);
                          resetForm();
                          setSubmitting(false);
                        }

                        if(req.status === 200){
                          // console.log(response);
                          cookie.save('decuser', response.jwt, { path: '/' });
                          cookie.save('decuserid', response.user.id, { path: '/' });
                          cookie.save('decusername', response.user.username, { path: '/' });
                          dispatch({type: 'SET_USER', payload: response.user});
                          dispatch({type: 'SET_JWT', payload: response.jwt});
                          props.history.push('/me');
                        }
                      })();
                  }}
                  >
                  {( {values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        placeholder="Enter email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={touched.email && errors.email ? "error" : null}
                      />
                      <Form.Text className="text-muted">
                      {touched.email && errors.email
                        ? <span>{errors.email}</span>
                        : <span>your registered email</span>
                      }
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={touched.password && errors.password ? "error" : null}
                      />
                      <Form.Text className="text-muted">
                      {touched.password && errors.password
                        ? <span>{errors.password}</span>
                        : <span>your account password.</span>
                      }
                      </Form.Text>
                    </Form.Group>

                    {isSubmitting
                      ? <Loading/>
                      :
                      <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                        Login
                      </Button>
                    }
                  </Form>
                )}
              </Formik>
              <Row className="mt-4 pl-2">
                <Link to='/forgot_password'>Forgot your password?</Link>
              </Row>
              {/* Show errors from API */}
              {error.length > 0
                ?
                <ApiError error={error} className="mt-3"/>
                : null
              }
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
    </>
  )
}

export default Login;
