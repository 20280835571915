const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOCIAL':
      return {
        ...state,
        social: action.payload
      };

    case 'SET_DAYS':
      return {
        ...state,
        days: action.payload
      };

    case 'SET_ABOUT':
      return {
        ...state,
        about: action.payload
      };

    case 'SET_CREW':
      return {
        ...state,
        crew: action.payload
      };

    case 'SET_PARTNERS':
      return {
        ...state,
        partners: action.payload
      };
    case 'SET_CONTRIBUTORS':
      return {
        ...state,
        contributors: action.payload
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
      case 'SET_JWT':
        return {
          ...state,
          jwt: action.payload
        };
    default:
      return state;
    }
};

export default Reducer;
