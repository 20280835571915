import React from 'react';
import {createUseStyles} from 'react-jss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const styles = createUseStyles({
  download:{
    marginRight: '.6rem',
  }
})

const DayLink = (props) => {
  const classes = styles();

  return (
    <a href={props.url}
      target="_blank"
      rel="noreferrer">
      <FontAwesomeIcon icon={faLink} className={classes.download}/>
      {props.title ? props.title : props.url}</a>
  )
}

export default DayLink
