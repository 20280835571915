import React from 'react';

import createMarkup from '../utils/createMarkup';

const HtmlText = (props) => {
  return (
    <div
    dangerouslySetInnerHTML={createMarkup(props.content)}
    className={props.className}
    ></div>
  );
}

export default HtmlText;
