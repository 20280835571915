import React from 'react';

import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';

import Header from '../../components/header';

const ForgotResponse = (props) => {
  return(
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Container className="h-100">
        <Row className="h-100">
          <Col md={{offset: 2}}className="h-100 d-table">
            <div className="d-table-cell align-middle">
              <h1>Please check your email for the confirmation message.</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
    </>
  )
}

export default ForgotResponse;
