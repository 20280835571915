import React,{useEffect} from 'react';
import {Link} from 'react-router-dom';

import Col from 'react-bootstrap/col';
import Row from 'react-bootstrap/row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'

import {BASE_URL} from '../../constants';
import HtmlText from '../../components/htmlText';
import './user.css';

const Profile = (props) => {
  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')
  },[])

  return (
    <>
      <Col md={{span:2}}>
        <Row>
          {props.user.picture
            ?
            <img
              src={`${BASE_URL}${props.user.picture.formats.thumbnail.url}`}
              alt={props.user.name}
              className="image-fluid img-thumbnail"/>
            : <FontAwesomeIcon icon={faUser} className="person-icon"/>
          }
        </Row>

        {props.edit
          ?
          <Row className="mt-5">
            <Link
              to='/me/edit'
              className='user-edit'
              >Edit profile</Link>
          </Row>
          :null
        }
      </Col>
      <Col
        md={{span:6, offset:2}}
        className="ml-0 pl-2"
        >
          {props.user.name
            ?<h1>{props.user.name}</h1>
            :<h1>{props.user.username}</h1>
          }

          {props.user.occupation
            ?<h2>{props.user.occupation}</h2>
            :null
          }

          {props.user.city
            ?<h3>{props.user.city}</h3>
            :null
          }
          {props.user.interests
            ?<HtmlText
              content={props.user.interests}
              className="mt-5"
            />
            :null
          }
        </Col>
      </>
  )
}

export default Profile
