import React,{useState, useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import Container from 'react-bootstrap/container';
import Col from 'react-bootstrap/col';
import Row from 'react-bootstrap/row';


import {API_DAYS, API_ABOUT, API_PARTNERS, BASE_URL} from '../../constants';
import Loading from '../../components/loading';
import Header from '../../components/header';
import VideoPlayer from '../../components/video';
import AboutRow from '../../components/about';
import DayCard from '../home/day';

import {Context} from '../../hooks/store';

import './about.css';

const styles = createUseStyles({
  title:{
    fontSize: '1rem'
  }
})


const About = (props) => {
  const [state, dispatch] = useContext(Context);
  const [daysLoaded, setDaysLoaded] = useState(false);
  const [aboutLoaded, setAboutLoaded] = useState(false);
  const [crewLoaded, setCrewLoaded] = useState(false);
  const [contributorsLoaded, setContributorsLoaded] = useState(false);
  const [partnersLoaded, setPartnersLoaded] = useState(false);
  const classes = styles();

  useEffect(() =>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint, eventType){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      dispatch({type: eventType, payload: aJson});
    }

    if(!state.days.length && !daysLoaded){
      setDaysLoaded(true);
      fetchData(API_DAYS, 'SET_DAYS');
    }

    if(!Object.entries(state.about).length && !aboutLoaded){
      setAboutLoaded(true);
      fetchData(API_ABOUT, 'SET_ABOUT');
    }

    if(!state.crew.length && !crewLoaded){
      setCrewLoaded(true);
      fetchData(`${BASE_URL}/users?Position=staff&_sort=name:ASC`, 'SET_CREW');
    }

    if(!state.partners.length && !partnersLoaded){
      setPartnersLoaded(true);
      fetchData(API_PARTNERS, 'SET_PARTNERS');
    }

    if(!state.contributors.length && !contributorsLoaded){
      setContributorsLoaded(true);
      fetchData(`${BASE_URL}/users?Position=contributor&_sort=name:ASC`, 'SET_CONTRIBUTORS');
    }

  },[dispatch, state, daysLoaded, aboutLoaded, crewLoaded, contributorsLoaded, partnersLoaded])

  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Col md={{span:12}} className="mt-10 mb-5">
        <Container className="pr-md-0 pl-md-0 mb-6" fluid>
          <Row className="row-cols-4 row-cols-md-6">
            {state.days.length > 0
              ?
              <>
                {state.days.map((day) => (
                  <Col key={`d-${day.index}`} className="">
                  <DayCard
                    header='Day'
                    headerClass='about-card-header'
                    index={day.index}
                    indexClass='about-card-index'
                    title={day.title}
                    titleClass={`about-card-title ${classes.title}`}
                    className="about-card mb-3  mr-0 mr-md-1"
                  />
                  </Col>
                ))}
              </>
              :
              <Loading/>
            }
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col md={{span:4}}>
              {state.about
                ?
                <>
                  {state.about.video
                    ?
                    <Row>
                      <VideoPlayer src={{youtube_id:state.about.video}}/>
                    </Row>
                    :null
                  }
                  {state.about.info
                    ?
                    <AboutRow
                      txt={state.about.info}
                      className='mt-3 mb-5'
                    />
                    :null
                  }
                </>
                :
                <Loading/>
              }
            </Col>

            <Col md={{span:2, offset: 1}}>
              {state.crew.length > 0
                ?
                <>
                <h2>Staff</h2>
                  <ul className="about-staff">
                    {state.crew.map((member) =>(
                      <li key={`s-${member.id}`} className="mb-3">
                        <a
                          href={member.url ? member.url : `/person/${member.slug}`}
                          target='_blank'
                          rel="noreferrer"
                        >
                          <span className="person">{member.name}</span> <br/>
                          <span className="role pl-2">{member.duty ? member.duty : null}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
                :
                <Loading/>
              }
              {state.contributors.length > 0
                ?
                <>
                <h2 className="mt-5">Contributors</h2>
                  <ul className='about-staff'>
                    {state.contributors.map((member) =>(
                      <li key={`s-${member.id}`} className='mb-1'>
                        <Link to={`/person/${member.slug}`} className='person'>
                          {member.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              : null
              }
            </Col>
            <Col md={{span:3, offset: 1}}>
              {state.partners.length > 0
                ?
                <>
                <h2>Sponsors & partners </h2>
                  <ul className='about-staff'>
                    {state.partners.map((partner) =>(
                      <li key={`p-${partner.id}`} className='mb-2'>
                        <a
                          href={partner.url}
                          target='_blank'
                          rel="noreferrer"
                        >
                          <img src={`${BASE_URL}${partner.logo.formats.thumbnail.url}`} alt={partner.name}/>
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
                :
                <Loading/>
              }
            </Col>
          </Row>
        </Container>
        </Col>
      </Col>
    </>
  )
}

export default About;
