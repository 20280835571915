import React,{useContext} from 'react';
import {Link} from 'react-router-dom';

import Col from 'react-bootstrap/col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/nav';
import Footer from './footer';
import './header.css';

import {Context} from '../hooks/store';


const Header = (props) => {
  const [state] = useContext(Context);

  return (
    <Col md={{span:3}} xs={{span:12}} lg={{span:3}} xl={{span:2}}className="nav-container flex-shrink-1">
      <Navbar expand="md" className="flex-md-column flex-row align-items-start">

        <Navbar.Brand
          as={Link}
          to='/'
          className="nav-title d-none d-sm-none d-md-block"
          style={props.mainColor ? {color: props.mainColor} : null}
        >DECAMERON <br/>PROJECT
        </Navbar.Brand>

        <Navbar.Brand
          as={Link}
          to='/'
          className="nav-title d-md-none"
          style={props.mainColor ? {color: props.mainColor} : null}
        >DECAMERON PROJECT
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="mb-5" id="basic-navbar-nav">
          <Nav className="flex-md-column w-100 justify-content-between">
            <Nav.Link as={Link}
              style={props.secondaryColor ?{color: props.secondaryColor} : null }
             to='/about'>About</Nav.Link>
            <Nav.Link as={Link}
              style={props.secondaryColor ?{color: props.secondaryColor} : null }
             to='/news'>News</Nav.Link>
            <Nav.Link as={Link}
              style={props.secondaryColor ?{color: props.secondaryColor} : null }
             to='/contact'>Contact</Nav.Link>

           {state.jwt
              ?
              <>
                <Nav.Link as={Link}
                  style={props.secondaryColor ?{color: props.secondaryColor} : null }
                  id='nav-username'
                  to='/me'>{state.user.username}</Nav.Link>
                <br/>
                <Nav.Link as={Link}
                  style={props.secondaryColor ?{color: props.secondaryColor} : null }
                 to='/logout'>Logout</Nav.Link>
              </>
              :
              <>
                <Nav.Link as={Link}
                  style={props.secondaryColor ?{color: props.secondaryColor} : null }
                 to='/register'>Register</Nav.Link>
                <br/>
                <Nav.Link as={Link}
                  style={props.secondaryColor ?{color: props.secondaryColor} : null }
                 to='/login'>Log in</Nav.Link>
              </>
            }
          </Nav>
          <Footer className="d-md-none mt-4"/>
        </Navbar.Collapse>
        <Footer
          className="d-none d-sm-none d-md-block mt-3"
          mainColor = {props.mainColor}
          secondaryColor = {props.secondaryColor}
        />
      </Navbar>
    </Col>
  )
}

export default Header;
