import React from 'react';

import Row from 'react-bootstrap/row';
import HtmlText from './htmlText';

const AboutRow = (props) => {
  return (
    <Row className={props.className}>
      {props.title
        ? <h1>{props.title}</h1>
        :null
      }

      <HtmlText
        content={props.txt}
        className="copy"
        />
    </Row>
  );
}


export default AboutRow;
