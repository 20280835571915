import React from 'react';

import Row from 'react-bootstrap/row';
import Spinner from 'react-bootstrap/spinner';

const Loading = (props) => {
  return (
    <Row>
      <Spinner animation="border" role="status" className="mt-5 ml-5">
        </Spinner>
    </Row>
  )
}

export default Loading;
