import React, {useContext, useEffect} from 'react';

import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';

import {Context} from '../../hooks/store';
import Header from '../../components/header';

const RegisterSuccess = (props) => {
  const [state] = useContext(Context);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('');
  },[])

  return(
    <>
      <Header/>
        <Col className="flex-grow-1">
          <Container className="h-100">
            <Row className="h-100">
              <Col md={{offset: 2}}className="h-100 d-table">
                <div className="d-table-cell align-middle">
                  <h1 className="mb-5">Registration Successful</h1>
                  <h2>{state.user.name}</h2>
                  <p>Please check your email (<span className="emph">{state.user.email}</span>) and click the confirmation link to continue to Decameron Project</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
    </>
  )
}

export default RegisterSuccess;
