import React from 'react'

import Row from 'react-bootstrap/row';

const ApiError = (props) => {
  return (
    <Row>
      {props.error.length > 0
        ? <h3
            className={`${props.className} error-message`}
            style={props.style ?props.style :null}
          >
          {props.error.map((items) =>(
              <>
              {items.messages.map((message) =>(
                <span>{message.message}</span>
              ))}
              </>
          ))}
        </h3>
        :null
      }
    </Row>

  )
}

export default ApiError
