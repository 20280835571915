import React,{useState, useEffect} from 'react';

import Col from 'react-bootstrap/col';

import {BASE_URL} from '../../constants';
import Header from '../../components/header';
import Date from '../../components/date';
import HtmlText from '../../components/htmlText';

const NewsItem = (props) => {
  const [item, setItem] = useState([]);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      setItem(aJson);
    }

    if(!item.length){
      fetchData(`${BASE_URL}/news-items?slug=${props.match.params.slug}`)
    }
  },[item, props.match.params.slug])

  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Col md={{span:6, offset:2}} className="mt-10 mb-5">
          {item.length > 0
            ?
            <div>
              {item[0].picture
                ?<img
                  src={`${BASE_URL}${item[0].picture.formats.small.url}`}
                  alt={item[0].title}
                  className="img-fluid mb-4"
                  />
                :null
              }
              <h1>{item[0].title}</h1>
                <Date
                  date={item[0].created_at}
                  className="mb-3"
                />
                <HtmlText
                  content={item[0].content}
                  className='mt-4'
                />
            </div>
            :null
          }
        </Col>
      </Col>
    </>
  );
}

export default NewsItem;
