import React,{useContext, useState, useEffect} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

// wysiwyg editor libs
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/editor.css';

import Row from 'react-bootstrap/row';
import Container from 'react-bootstrap/container';
import Col from 'react-bootstrap/col';
import Form from 'react-bootstrap/form';
import Button from 'react-bootstrap/button';

import Loading from '../../components/loading';
import Header from '../../components/header';
import ApiError from '../../components/apiError';
import {Context} from '../../hooks/store';
import {BASE_URL} from '../../constants';
import {checkIfFilesAreTooBig, checkIfFilesAreCorrectType} from '../../utils/'


// Schema for yup
const validationSchema = Yup.object().shape({
  username: Yup.string()
  .min(3, "*Username must have at least 3 characters")
  .max(100, "*Username can't be longer than 100 characters"),
  // .required("*Username is required"),
  name: Yup.string()
  .min(6, "*Full name must have at least 3 characters")
  .max(100, "*Full name can't be longer than 100 characters"),
  // .required("*Full name is required")
  picture: Yup.array()
  .nullable()
  .test('is-correct-file', 'VALIDATION_FIELD_FILE_BIG', checkIfFilesAreTooBig)
  .test(
     'is-big-file',
     'VALIDATION_FIELD_FILE_WRONG_TYPE',
     checkIfFilesAreCorrectType
   )
});


const EditProfile = (props) => {
  const [state] = useContext(Context);
  const [error, setError] = useState([]);
  const [editorState, setEditorState] = useState();
  // store converted text extracted from the editor
  const  [convertedContent, setConvertedContent] = useState(null);

  //Put text data to api
  // include jwt auth key
  async function postData(url, payload){
    const options = {
      method: 'PUT',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.jwt}`
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  //Post profile image to api
  // include image, auth token and ref fields
  async function postFile(url, payload){
    const data = new FormData();
    data.append('files', payload.file);
    data.append('ref', payload.ref);
    data.append('refId', payload.refId);
    data.append('source', payload.source);
    data.append('field', payload.field);

    const options = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': `Bearer ${state.jwt}`
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')


    // redirect user to login
    if(!state.jwt){
      props.history.push('/login');
    } else {
      //convert html to content state for Draft-js
      const contentBlock = convertFromHTML((state.user.interests ? state.user.interests : ''));
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock);
        const _editorState = EditorState.createWithContent(contentState);
        setEditorState(_editorState)
      }

    }

  },[state.jwt, props.history, state.user.interests])


  const handleEditorChange = (_state) =>{
    setEditorState(_state);
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }



  return (
    <>
      <Header/>
        <Col className="flex-grow-1">
          <Col md={{span:5, offset:1}} className="mt-10 mb-5">

            {Object.entries(state.user).length > 0
              ?
              <Formik
                initialValues={{
                  username:state.user.username,
                  name: state.user.name,
                  city: state.user.city,
                  phone: state.user.phone,
                  occupation: state.user.occupation,
                  interests: state.user.interests
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    // When button submits form and form is in the process of submitting, submit button is disabled
                    setSubmitting(true);

                    (async function(){
                      // update editor converted content
                      convertContentToHTML();

                      // Send to API new user data
                      const req = await postData(`${BASE_URL}/users/${state.user.id}`, {
                        username: values.username,
                        name: values.name,
                        city: values.city,
                        phone: values.phone,
                        occupation: values.occupation,
                        interests: DOMPurify.sanitize(values.interests),
                      })
                      // error handler
                      if(req.status === 400){
                        const response = await req.json();
                        setError(response.message);
                        resetForm();
                        setSubmitting(false);
                      }
                      // success handler
                      if(req.status === 200){
                        // const response = await req.json();
                        // dispatch({type: 'SET_USER', payload: response});
                        props.history.push('/me');
                      }

                      // post image if there is a file
                      if(values.pictureFile){
                        const reqFile = await postFile(`${BASE_URL}/upload`, {
                          file: values.pictureFile,
                          ref: 'user',
                          source: 'users-permissions',
                          refId: state.user.id,
                          field: 'picture'
                        })
                        // //
                        // if(reqFile.status === 200){
                        //   console.log('File ok')
                        // }

                        // error handler
                        if(reqFile.status === 400){
                          const response = await reqFile.json();
                          setError(response.message);
                          resetForm();
                          setSubmitting(false);
                        }
                      }



                    })();
                }}
              >
            {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue}) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  name="username"
                  required type="text"
                  placeholder="Username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username ? values.username : ''}
                  className={touched.username && errors.username ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.username && errors.username
                  ? <span>{errors.username}</span>
                  : <span>Your nickname</span>
                }
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  required type="text"
                  placeholder="Name Lastname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name ? values.name : ''}
                  className={touched.name && errors.name ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.name && errors.name
                  ? <span>{errors.name}</span>
                  : <span>Your name</span>
                }
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  name="city"
                  required type="text"
                  placeholder="City"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city ? values.city : ''}
                  className={touched.city && errors.city ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.city && errors.city
                  ? <span>{errors.city}</span>
                  : <span>Where do you live</span>
                }
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  name="phone"
                  type="tel"
                  placeholder="Phone number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone ? values.phone : ''}
                  className={touched.phone && errors.phone ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.phone && errors.phone
                  ? <span>{errors.phone}</span>
                  : <span>Your phone number</span>
                }
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="occupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  name="occupation"
                  type="text"
                  placeholder="What do you do?"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.occupation ? values.occupation : ''}
                  className={touched.occupation && errors.occupation ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.occupation && errors.occupation
                  ? <span>{errors.occupation}</span>
                  : <span>Your occupation</span>
                }
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="interests">
                <Form.Label>Interests</Form.Label>
                <Form.Control
                  name="interests"
                  as={Editor}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    options: ['inline']
                  }}
                  placeholder="Your interests"
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  defaultContentState={editorState}
                  onChange={(e)=>{
                    convertContentToHTML(); //convert and sanitize content
                    setFieldValue('interests', convertedContent) //set editor content to values
                  }}
                  onBlur={handleBlur}
                  value={state.user.interests}
                  className={touched.interests && errors.interests ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.interests && errors.interests
                  ? <span>{errors.interests}</span>
                  : <span>Your interests</span>
                }
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="picture">
                <Form.Label>Picture</Form.Label>
                <Form.Control
                  name="picture"
                  type="file"
                  onChange={(e)=>{setFieldValue('pictureFile', e.currentTarget.files[0])}}
                  onBlur={handleBlur}
                  className={touched.picture && errors.picture ? "error" : null}
                />
                <Form.Text className="text-muted">
                {touched.picture && errors.picture
                  ? <span>{errors.picture}</span>
                  : <span>Your picture</span>
                }
                </Form.Text>
              </Form.Group>

              {isSubmitting
                ? <Loading/>
                :
                <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                  Update profile
                </Button>
              }
              {/* Show errors from API */}
              {error.length > 0
                ?
                <ApiError error={error} className="mt-3"/>
                : null
              }
            </Form>
          )}
        </Formik>
              :<Loading/>
            }
          </Col>
        </Col>
    </>
  );
}

export default EditProfile;
