import React from 'react';
import { withSize } from 'react-sizeme'

import Card from 'react-bootstrap/card';

import Title from './title';
import Number from './number';

const DayCard = (props) => {
  // update margin top from width size
  function calculateMtop(width){
    let vw = window.visualViewport.width;

    // setup breakpoints
    if (vw > 1400 ) {
        return width*0.15
    }

    if (vw > 1200 && vw < 1400 ) {
        return width*0.005
    }

    if (vw > 992 && vw < 1200 ) {
        return width*0.01
    }

  }

  return(
    <Card
      key={props.index}
      className={props.className}
      style={{height: props.size.width}}
      ref={(node)=> {
        if(node && props.mainColor){
          node.style.setProperty('border-color', `${props.mainColor}`, 'important')
        }
      }}
    >
      <Card.Header
        className='p-0'
        style={{backgroundColor: 'transparent', border:'none'}}
      >
        <h5 className={`${props.headerClass} text-center`} style={props.headerStyle}>{props.header ?props.header :null}</h5>
      </Card.Header>
      <Card.Body className="py-0">
        <Card.Title style={{marginTop: calculateMtop(props.size.width)}}>
          <Number
            to={`/day/${props.index}`}
            state={props.state}
            txt={props.index}
            className={props.indexClass}
            mainColor={props.mainColor}
            size={props.size}
          />
        </Card.Title>
      </Card.Body>
      <Card.Footer
        className='p-0 text-center'
        style={{backgroundColor: 'transparent', border: 'none'}}
      >
        <Title txt={props.title ?props.title :null} className={props.titleClass} style={props.titleStyle}/>
      </Card.Footer>

    </Card>
  )
};

export default withSize({ monitorWidth: true })(DayCard)
