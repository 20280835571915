import React, {useState, useContext, useEffect} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

import {API_SOCIAL} from '../constants';
import {Context} from '../hooks/store';
import Loading from './loading';

import './footer.css';

const Footer = (props) => {
  const [state, dispatch] = useContext(Context);

  useEffect(() =>{
    async function fetchData(endpoint, eventType){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      dispatch({type: eventType, payload: aJson});
    }

    (async function() {
      if (!Object.entries(state.social).length) {
        fetchData(API_SOCIAL, 'SET_SOCIAL');
      }

    })()

  }, [dispatch, state.social])

  return (
    <div className={props.className}>
      <a
        href="https://ensemblevortex.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://api.decameron.ch/uploads/Ensemble_Vortex_D_2_0a49cac597.png"
          alt="Ensemble Vortex"
          className="footer-img mb-2"
        />
      </a>

      {state.social
        ?
        <ul className={`footer-list`}>
          <li className="ml-0">
            <a
              href={`https://instagram.com/${state.social.Instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              style = {props.mainColor ?{color: props.mainColor} : null}
              ><FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li className="ml-2">
            <a
              href={`https://twitter.com/${state.social.Twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              style = {props.mainColor ?{color: props.mainColor} : null}
              ><FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li className="ml-2">
            <a
              href={`https://facebook.com/${state.social.Facebook}`}
              target="_blank"
              rel="noopener noreferrer"
              style = {props.mainColor ?{color: props.mainColor} : null}
              ><FontAwesomeIcon icon={faFacebook} />
            </a>
          </li>
          <li className="ml-2">
            <a
              href={`https://youtube.com/channel/${state.social.Youtube}`}
              target="_blank"
              rel="noopener noreferrer"
              style = {props.mainColor ?{color: props.mainColor} : null}
              ><FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
        </ul>
        :
        <Loading/>
      }
    </div>
  )
}

export default Footer;
