import React from 'react';
import {createUseStyles} from 'react-jss';
import { Row, Accordion, Card } from 'react-bootstrap';

import HtmlText from '../../components/htmlText';

const styles = createUseStyles({
  noBorder: {
    border: 'none'
  },
  header: {
    backgroundColor: 'transparent'
  },
  button: {
    cursor: 'pointer',
    padding: '1rem',
    textDecoration: 'underline',
    marginBottom: '0px'
  }
})

const AccordionRow = (props) => {
  const classes = styles();

  return (
    <Row className={props.className}>
      <Accordion>
        <Card className={classes.noBorder}>
          <Card.Header
            className={`${classes.header} ${classes.noBorder} p-0`}
          >
            <Accordion.Toggle
              as={'h1'}
              variant="link"
              eventKey={props.id}
              className={classes.button}
            >
              {props.title}
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey={props.id}>
            <Card.Body>
              <HtmlText
                content={props.txt}
                className="copy"
                />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Row>
  );
}


export default AccordionRow;
