import React,{useContext, useEffect} from 'react'
import {Link} from 'react-router-dom';

import {Col, Row, Container} from 'react-bootstrap'

import Header from '../../components/header';
import DayCard from '../home/day';
import {Context} from '../../hooks/store';


const Submit = (props) => {
  const [state] = useContext(Context);

  useEffect(()=>{
    if(!state.jwt){
      props.history.push('/login');
    }
  },[props.history, state.jwt])


  return (
    <>
    <Header/>
    <Col className="flex-grow-1">
      <Col md={{span:11, offset:1}} className="mt-10 mb-5 pl-0">
        <Container className="pr-md-0 pl-md-0 mb-6">
          <Row>
            <Col md={{span:2}}>
              {props.location.state
                ?
                <DayCard
                  key={props.location.state.day.index}
                  header='Day'
                  headerClass='about-card-header'
                  index={props.location.state.day.index}
                  title={props.location.state.day.title}
                  indexClass='about-card-index'
                  // titleClass='about-card-title'
                  className="day-card mb-3  mr-0 mr-md-1"
                />
              :null
              }
            </Col>
            <Col md={{span:4, offset:1}}>
              <h1>Submit new content</h1>
              <p>What do you want to share?</p>
              <div className="mt-5">
                <ul>
                  <li>
                    <Link to={{
                        pathname: '/submit/video',
                        state: props.location.state
                      }}>
                      <h3>Video</h3>
                    </Link>
                  </li>
                  <li>
                    <Link to={{
                        pathname: '/submit/audio',
                        state: props.location.state
                      }}>
                      <h3>Audio file</h3>
                    </Link>
                  </li>
                  <li>
                    <Link to={{
                        pathname: '/submit/pdf',
                        state: props.location.state
                      }}>
                      <h3>PDF file</h3>
                    </Link>
                  </li>
                  <li>
                    <Link to={{
                        pathname: '/submit/link',
                        state: props.location.state
                      }}>
                      <h3>Link</h3>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Col>
    </>
  )
}

export default Submit
