import React from 'react';
import clip from 'text-clipper';
import {Link} from 'react-router-dom';

import Date from '../../components/date';
import HtmlText from '../../components/htmlText';

const Teaser = (props) => {
  return (
    <>
      <div
        key={props.item.id}
        className="mb-4"
      >
        <Link to={`/news/${props.item.slug}`}>
          <h1>{props.item.title}</h1>
        </Link>
        <Date
          date={props.item.created_at}
          className="mb-3"
        />
        <HtmlText
          content={clip(props.item.content, 140)}
          className='mt-2'
        />
      </div>
    </>
  );
}


export default Teaser;
