import React,{useState} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';
import Form from 'react-bootstrap/form';
import Button from 'react-bootstrap/button';

import Header from '../../components/header';
import ApiError from '../../components/apiError';
import {API_FORGOT} from '../../constants';
import Loading from '../../components/loading';

import '../../css/register.css';

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
  .email("*Must be a valid email address")
  .max(100, "*Email must be less than 100 characters")
  .required("*Email is required")
});

const ForgotPassword = (props) =>{
  const [error, setError] = useState([]);

  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  return(
    <>
      <Header/>
        <Col className="flex-grow-1">
          <Container className="h-100">
            <Row className="h-100">
              <Col md={{span:5,offset: 2}}className="h-100 d-table">
                <div className="d-table-cell align-middle">
                  <h1 className="mb-4">Forgot your password?</h1>
                    <Formik
                      initialValues={{email:""}}
                      validationSchema={validationSchema}
                      onSubmit={(values, {setSubmitting, resetForm}) => {
                          // When button submits form and form is in the process of submitting, submit button is disabled
                          setSubmitting(true);

                          (async function(){
                            const req = await postData(API_FORGOT, {
                              email: values.email
                            })

                            const response = await req.json();

                            if(req.status === 400){
                              setError(response.message);
                              resetForm();
                              setSubmitting(false);
                            }

                            if(req.status === 200){
                              props.history.push('/forgot_response');
                            }
                          })();
                      }}
                    >
                      {( {values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="email">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            name="email"
                            type="email"
                            placeholder="user@host.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className={touched.email && errors.email ? "error" : null}
                          />
                          <Form.Text className="text-muted">
                          {touched.email && errors.email
                            ? <span>{errors.email}</span>
                            : <span>Enter the email associated with your account</span>
                          }
                          </Form.Text>
                        </Form.Group>

                        {isSubmitting
                          ? <Loading/>
                          :
                          <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                            Recover password
                          </Button>
                        }
                      </Form>
                    )}
                  </Formik>
                  {/* Show errors from API */}
                  {error.length > 0
                    ?
                    <ApiError error={error} className="mt-3"/>
                    : null
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
    </>
  )
}

export default ForgotPassword;
