import React,{useContext} from 'react';
import {createUseStyles} from 'react-jss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {Context} from '../../hooks/store';
import Lock from './lock';

const styles = createUseStyles({
  download:{
    marginRight: '.6rem',
  }
})

const DayDownload = (props) => {
    const [state] = useContext(Context);
  const classes = styles();

  return (
    <>
    {props.locked && !state.jwt
      ?
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={`download-${props.fileId}`}>
              Login to access this content
            </Tooltip>
          }
        >
        <a href={props.locked && !state.jwt ?'/login' :props.url}
          target="_blank"
          rel="noreferrer"
          >
          <FontAwesomeIcon icon={faDownload} className={classes.download}/>
          {props.title ? props.title : props.filename}
          {props.locked
            ?
            <Lock/>
            : null
          }
        </a>
      </OverlayTrigger>
      :
      <a href={props.locked && !state.jwt ?'/login' :props.url}
        target="_blank"
        rel="noreferrer"
        >
        <FontAwesomeIcon icon={faDownload} className={classes.download}/>
        {props.title ? props.title : props.filename}
        {props.locked
          ?
          <Lock/>
          : null
        }
      </a>
    }
    </>
  )
}

export default DayDownload
