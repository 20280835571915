import React,{useContext} from 'react';
import {createUseStyles} from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons'

import {Context} from '../../hooks/store';

const styles = createUseStyles({
  lock:{
    marginLeft: '.6rem',
    fontSize: '0.7rem'
  }
})

const Lock = (props) => {
  const [state] = useContext(Context);
  const classes = styles();

  return (
    <>
      {!state.jwt
        ? <FontAwesomeIcon icon={faLock} className={`${classes.lock} ${props.className}`}/>
        : null
      }
    </>
  )
}

export default Lock
