export function checkIfFilesAreTooBig(files?: [File]): boolean {
  let valid = true
  if (files) {
    files.map(file => {
      const size = file.size / 1024 / 1024
      if (size > 10) {
        valid = false
      }
      return valid
    })
  }
  return valid
}

export function checkIfFilesAreCorrectType(files?: [File]): boolean {
  let valid = true
  if (files) {
    files.map(file => {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        valid = false
      }
      return valid
    })
  }
  return valid
}

export function checkForPDF(files): boolean {
  console.log(files);
  let valid = true
  if (files) {
    files.map(file => {
      console.log(file.type);
      if (!['application/pdf'].includes(file.type)) {
        valid = false
      }
      return valid
    })
  }
  return valid
}
