import React from 'react'

import {Col, Container} from 'react-bootstrap';

import Header from '../../components/header';

const SubmitResponse = (props) => {
  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Col md={{span:5, offset:1}} className="mt-10 mb-5 pl-0">
          <Container className="pr-md-0 pl-md-0 mb-6">
            <h1 className="mb-5">Thank you!</h1>
            <h2>the submission will be reviewed by our team and it will be published soon</h2>
          </Container>
        </Col>
      </Col>
    </>
  )
}

export default SubmitResponse
