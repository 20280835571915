import React,{useEffect, useState, useContext} from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import cookie from 'react-cookies';
import './App.css';

import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row'

import Home from './routes/home/';
import About from './routes/about/';
import News from './routes/news/';
import NewsItem from './routes/news/newsItem';
import Contact from './routes/contact';
import Day from './routes/day/';
import DayContent from './routes/day/dayContent';
import Register from './routes/auth/register';
import RegisterSuccess from './routes/auth/registerSuccess';
import Login from './routes/auth/login';
import LogOut from './routes/auth/logOut';
import ForgotPassword from './routes/auth/forgot_password';
import ForgotResponse from './routes/auth/forgot_response';
import ResetPassword from './routes/auth/reset_password';
import ResetSuccess from './routes/auth/resetSucess';
import PrivateProfile from './routes/user/privateProfile';
import PublicProfile from './routes/user/publicProfile';
import EditProfile from './routes/user/editProfile';
import Submit from './routes/submit';
import SubmitByType from './routes/submit/byType';
import SubmitResponse from './routes/submit/response';
import NotFound from './routes/notFound';

import {Context} from './hooks/store';


const  App = (props) => {
  const [state, dispatch] = useContext(Context);
  const [jwt, setJwt] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);

  useEffect(()=>{
    setJwt(cookie.load('decuser'));
    setUserId(cookie.load('decuserid'));
    setUserName(cookie.load('decusername'));

    dispatch({type: 'SET_USER', payload: {username: userName, id: userId}});
    dispatch({type: 'SET_JWT', payload: jwt});
  },[dispatch, jwt, userId, userName])

  return (
      <Container fluid>
        <Row className='min-vh-100 flex-column flex-md-row'>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/news" component={News} />
              <Route exact path="/news/:slug" component={NewsItem} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/day/:id" component={Day} />
              <Route exact path="/day/:dayId/content/:contentId" component={DayContent} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/register/success" component={RegisterSuccess} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={LogOut} />
              <Route exact path="/forgot_password" component={ForgotPassword} />
              <Route exact path="/forgot_response" component={ForgotResponse} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/reset_success" component={ResetSuccess} />
              <Route exact path="/me" component={PrivateProfile} />
              <Route exact path="/me/edit" component={EditProfile} />
              <Route exact path="/person/:slug" component={PublicProfile} />
              <Route exact path="/submit" component={Submit} />
              <Route exact path="/submit/response" component={SubmitResponse} />
              <Route exact path="/submit/:type" component={SubmitByType} />
              <Route exact path="/404" component={NotFound} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </Row>
      </Container>
  );
}

export default App;
