import React,{useState,useContext,useEffect} from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';

import {Form, Button} from 'react-bootstrap';

import {BASE_URL} from '../../constants';
import {Context} from '../../hooks/store';
import Loading from '../../components/loading';
import ApiError from '../../components/apiError';

// Schema for yup
const validationSchema = Yup.object().shape({
  title: Yup.string()
  .min(3, "* min 3 characters *")
  .max(100, "* less than 100 characters *")
  .required("* Title is required *"),
  url: Yup.string()
  .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter a valid  url!'
        )
  .required("* Please enter a valid url *")
});


const Link = (props) => {
  const [state] = useContext(Context);
  const [error, setError] = useState([]);

  useEffect(()=>{
    // no jwt key > redirect
    if (!state.jwt) {
      props.history.push('/login');
    }
  },[state, props])


  async function postData(url, payload){
    const options = {
      method: 'POST',
      body: JSON.stringify(payload, null, 2),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.jwt}`
      }
    }

    const req = await fetch(url, options);
    return req;
  }

  return (
    <>
        <Formik
          initialValues={{title:"", url:""}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);

              (async function(){
                const req = await postData(`${BASE_URL}/links`, {
                  title: values.title,
                  url: values.url,
                  day: props.toId,
                  contributor: state.user.id,
                  published_at: null
                })

                const response = await req.json();

                if(req.status === 400){
                  setError(response.message);
                  resetForm();
                  setSubmitting(false);
                }

                if(req.status === 200){
                  props.history.push('/submit/response');
                }
              })();
          }}
          >
          {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                type="title"
                placeholder="My contribution"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className={touched.title && errors.title ? "error" : null}
              />
              <Form.Text className="text-muted">
              {touched.title && errors.title
                ? <span>{errors.title}</span>
                : <span>The name of the resource</span>
              }
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="url">
              <Form.Label>URL</Form.Label>
              <Form.Control
                name="url"
                type="url"
                placeholder="https://www.example.com"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                className={touched.url && errors.url ? "error" : null}
              />
              <Form.Text className="text-muted">
              {touched.url && errors.url
                ? <span>{errors.url}</span>
                : <span>Link address</span>
              }
              </Form.Text>
            </Form.Group>

            {isSubmitting
              ? <Loading/>
              :
              <Button variant="secondary" type="submit" disabled={isSubmitting} className="mt-3">
                Submit
              </Button>
            }
          </Form>
        )}
      </Formik>
      {/* Show errors from API */}
      {error.length > 0
        ?
        <ApiError error={error} className="mt-3"/>
        : null
      }
    </>
  )
}

export default Link
