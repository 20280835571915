import React, {useEffect, useState} from 'react';

import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import Row from 'react-bootstrap/row';

import {BASE_URL} from '../../constants';
import Header from '../../components/header';
import Loading from '../../components/loading';

import Teaser from './teaser';

const News = (props) => {
  const [items, setItems] = useState([]);

  useEffect(()=>{
    //remove background image
    document.body.style.backgroundImage = ('')

    async function fetchData(endpoint){
      const a = await fetch(endpoint);
      const aJson = await a.json();

      setItems(aJson)
    }

    if (!items.length >0) {
      fetchData(`${BASE_URL}/news-items?_sort=created_at:DESC`)
    }
  },[items])

  return (
    <>
      <Header/>
      <Col className="flex-grow-1">
        <Col md={{span:11, offset:1}} className="mt-10 mb-5">
          {items.length>0
            ?
            <Container>
              <Row>
                {/* column for mobile devices */}
                <Col className="d-block d-sm-block d-md-none">
                  {items.map((item, index)=>(
                    <Teaser key={`tm-${item.id}`} item={item}/>
                  ))}
                </Col>
                {/* columns for desktop */}
                <Col md={{span: 4}} className="d-none d-sm-none d-md-block">
                  {items.map((item, index)=>(
                    <>
                      {index%2 ===0
                        ?<Teaser key={`td-${item.id}`} item={item}/>
                        :null
                      }
                    </>
                  ))}
                </Col>
                <Col md={{span: 4, offset: 1}} className="d-none d-sm-none d-md-block">
                  {items.map((item, index)=>(
                    <>
                      {index%2 !==0
                        ?<Teaser key={`td-${item.id}`} item={item}/>
                        :null
                      }
                    </>
                  ))}
                </Col>
              </Row>
            </Container>
            :
            <Loading/>
          }
        </Col>
      </Col>
    </>
  );
}

export default News;
